<div class="input-group mb-3">
  <ng-content></ng-content>

  <div class="input-group-append">
    <button *ngIf="exportQr && !emptyValue" (click)="showQrExporter()" class="btn btn-primary" type="button">
      <i class="fas fa-qrcode"></i>
    </button>
    <button *ngIf="importQr" (click)="showQrImporter()" class="btn btn-primary" type="button">
      <i class="fas fa-camera"></i>
    </button>
    <button (click)="toggleShow()" class="btn btn-primary" type="button">
      <i class="fas" [ngClass]="{'fa-eye-slash': !isHidden, 'fa-eye': isHidden}"></i>
    </button>
  </div>
</div>
