import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SignupComponent} from '../signup/signup.component';
import {EthersService} from '../../../core/ethers/services/ethers.service';
import {LoginComponent} from '../login/login.component';

@Component({
  selector: 'app-connect-modal',
  templateUrl: './connect-modal.component.html',
  styleUrls: ['./connect-modal.component.scss']
})
export class ConnectModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private ethersService: EthersService
  ) {
  }

  ngOnInit(): void {
  }

  showWebWalletSignUp() {

    if (this.ethersService.getSavedKey()) {
      this.modalService.open(LoginComponent, {scrollable: true});
    } else {
      this.modalService.open(SignupComponent, {size: 'lg', scrollable: true});
    }
    this.activeModal.close('Close click');

  }

  async metamaskConnect() {
    await this.ethersService.metamaskConnect();
    this.activeModal.close('Close click');

  }
}
