import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./views/pages/home/home.module').then(m => m.HomeModule)
  // },
  {
    path: '',
    loadChildren: () => import('./views/pages/nft/nft.module').then(m => m.NftModule)
  },
  {
    path: 'nfts',
    loadChildren: () => import('./views/pages/nfts/nfts.module').then(m => m.NftsModule)
  },
  {
    path: 'nfts/:contractAddress',
    loadChildren: () => import('./views/pages/nfts/nfts.module').then(m => m.NftsModule)
  },
  {
    path: 'nft',
    loadChildren: () => import('./views/pages/nft/nft.module').then(m => m.NftModule)
  },
  {
    path: 'nft/:contractAddress',
    loadChildren: () => import('./views/pages/nft/nft.module').then(m => m.NftModule)
  },
  {
    path: 'nfts-template',
    loadChildren: () => import('./views/pages/nfts-template/nfts-template.module').then(m => m.NftsTemplateModule)
  },
  {
    path: 'nft',
    loadChildren: () => import('./views/pages/nfts/nfts.module').then(m => m.NftsModule)
  },
  {
    path: 'nft/:contractAddress',
    loadChildren: () => import('./views/pages/nfts/nfts.module').then(m => m.NftsModule)
  },
  {
    path: 'prices',
    loadChildren: () => import('./views/pages/prices/prices.module').then(m => m.PricesModule)
  },
  {
    path: 'contracts',
    loadChildren: () => import('./views/pages/contracts/contracts.module').then(m => m.ContractsModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./views/pages/transfer/transfer.module').then(m => m.TransferModule)
  },
  {
    path: 'transfer/:contractAddress',
    loadChildren: () => import('./views/pages/transfer/transfer.module').then(m => m.TransferModule)
  },
  {
    path: 'burn',
    loadChildren: () => import('./views/pages/burn/burn.module').then(m => m.BurnModule)
  },
  {
    path: 'burn/:contractAddress',
    loadChildren: () => import('./views/pages/burn/burn.module').then(m => m.BurnModule)
  },
  {
    path: 'set-nfc',
    loadChildren: () => import('./views/pages/set-nfc/set-nfc.module').then(m => m.SetNfcModule)
  },
  {
    path: 'set-nfc/:contractAddress',
    loadChildren: () => import('./views/pages/set-nfc/set-nfc.module').then(m => m.SetNfcModule)
  },
  {
    path: 'transfer-ownership',
    loadChildren: () => import('./views/pages/transfer-ownership/transfer-ownership.module').then(m => m.TransferOwnershipModule)
  },
  {
    path: 'transfer-ownership/:contractAddress',
    loadChildren: () => import('./views/pages/transfer-ownership/transfer-ownership.module').then(m => m.TransferOwnershipModule)
  },
  {
    path: 'check',
    loadChildren: () => import('./views/pages/check/check.module').then(m => m.CheckModule)
  },
  {
    path: 'check/:contractAddress/:chain',
    loadChildren: () => import('./views/pages/check/check.module').then(m => m.CheckModule)
  },
  {
    path: 'smart-contract',
    loadChildren: () => import('./views/pages/contracts/smart-contract-form/smart-contract-form.module')
      .then(m => m.SmartContractFormModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./views/pages/wallet/wallet.module').then(m => m.WalletModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./views/pages/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'paper-wallet/:address/:privateKey',
    loadChildren: () => import('./views/pages/paper-wallet/paper-wallet.module').then(m => m.PaperWalletModule)
  },
  {
    path: 'print-certificate',
    loadChildren: () => import('./views/pages/print-certificate/print-certificate.module').then(m => m.PrintCertificateModule)
  },
  {
    path: 'add-chain',
    loadChildren: () => import('./views/pages/add-chain/add-chain.module').then(m => m.AddChainModule)
  },
  {
    path: 'documentation',
    loadChildren: () => import('./views/pages/documentation/documentation.module').then(m => m.DocumentationModule)
  },
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
