<div class="modal-body">
    <button (click)="openNewWalletModal()" class="btn btn-lg btn-light btn-block">
        {{'partials.signUp.newAccount.title' | translate}}
    </button>
    <button (click)="openImportWalletModal()" class="btn btn-lg btn-light btn-block">
        {{'partials.signUp.importKeystore.title' | translate}}
    </button>
    <button (click)="openImportPrivateKeyModal()" class="btn btn-lg btn-light btn-block">
        {{'partials.signUp.importPrivateKey.title' | translate}}
    </button>
    <button (click)="openImportMnemonicModal()" class="btn btn-lg btn-light btn-block">
        {{'partials.signUp.importMnemonicPhrase.title' | translate}}
    </button>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">{{ 'generic.close' | translate }}</button>
</div>

