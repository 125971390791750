import {Component, OnInit, ApplicationRef, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EthersService} from '../../../../core/ethers/services/ethers.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginComponent} from '../../login/login.component';

declare var $: any;

@Component({
  selector: 'app-import-private-key',
  templateUrl: './import-private-key.component.html',
  styleUrls: ['./import-private-key.component.scss']
})
export class ImportPrivateKeyComponent implements OnInit {
  privateKey: string;
  privateKeyErr: boolean;
  password: string;
  repeatPassword: string;

  passwordErr: boolean;
  repeatPasswordErr: boolean;
  passwordErrText: string;

  constructor(
    public activeModal: NgbActiveModal,
    private walletService: EthersService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.privateKey = '';
    this.privateKeyErr = false;
  }

  login() {
    if (this.verifyPassword()) {
      this.privateKeyErr = false;
      const wallet = this.walletService.importWalletFromPrivateKey(this.privateKey, this.password);
      if (wallet) {
        this.activeModal.close();
        this.modalService.open(LoginComponent);
      } else {
        this.privateKeyErr = true;
      }
    }
  }

  verifyPassword(): boolean {
    this.passwordErr = false;
    this.repeatPasswordErr = false;

    if (this.password !== this.repeatPassword) {
      this.translate.get('signUp.errorRepeatPassword').subscribe((errorRepeatPassword: any) => {
        this.passwordErrText = errorRepeatPassword;
        this.passwordErr = true;
        this.repeatPasswordErr = true;
      });

      return false;
    }
    if (!this.password) {
      this.translate.get('signUp.errorEmptyPassword').subscribe((errorEmptyPassword: any) => {
        this.passwordErrText = errorEmptyPassword;
        this.passwordErr = true;
      });
      return false;
    }
    this.passwordErrText = '';
    return true;

  }


}
