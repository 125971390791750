<div class="modal-header">
  <h4 class="modal-title text-body text-center">{{'partials.signUp.importMnemonicPhrase.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-show-hide-input-text>
    <input [(ngModel)]="mnemonic" type="password" class="form-control"
           [placeholder]="'partials.signUp.importMnemonicPhrase.mnemonicPhrase' | translate">
  </app-show-hide-input-text>
  <app-show-hide-input-text>
    <input [(ngModel)]="password" [ngClass]="{'is-invalid': passwordErr}" type="password"
           class="form-control" [placeholder]="'partials.signUp.importMnemonicPhrase.password' | translate">
  </app-show-hide-input-text>
  <app-show-hide-input-text>
    <input [(ngModel)]="repeatPassword" [ngClass]="{'is-invalid': repeatPasswordErr}"
           type="password" class="form-control"
           [placeholder]="'partials.signUp.importMnemonicPhrase.repeatPassword' | translate">
  </app-show-hide-input-text>

  <div *ngIf="passwordErrText" class="invalid mb-2">
    {{passwordErrText}}
  </div>
  <div *ngIf="mnemonicErr" class="invalid mb-2">
    {{ 'partials.signUp.importMnemonicPhrase.errors.errorMnemonicPhrase' | translate }}
  </div>
  <p class="text-justify">
    <small [innerHTML]="'partials.signUp.importMnemonicPhrase.rememberPassword' | translate">
    </small>
  </p>
</div>
<div class="modal-footer justify-content-center">
  <button (click)="login()" type="button" class="btn btn-primary float-left">
    {{ 'partials.login.login' | translate }}
  </button>
</div>
