// Angular
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
// RxJS
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {EthersService} from '../ethers/services/ethers.service';


/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  // intercept request and add token
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private ethersService: EthersService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request

    request = this.addAuthenticationToken(request);
    // console.log('----request----');
    // console.log(request);
    // console.log('--- end of request---');

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // console.log('all looks good');
            // http response status code
            // console.log(event.status);
          }
        },
        error => {
          // http response status code
          // console.log('----response----');
          // console.error('status code:');
          // tslint:disable-next-line:no-debugger
// return;

          if (
            request.url.includes('request-code') ||
            request.url.includes('login')
          ) {
            // We do another check to see if refresh token failed
            // In this case we want to logout user and to redirect it to login page

            // if (request.url.includes('refreshtoken')) {
            //   this.ethersService.logout();
            // }

            return Observable.throw(error);
          }

          if (error.status === 401) {
            // console.log(error, 'error!!!!!!!!');
            // this.ethersService.logout();
            // this.ethersService.login();

          }

          // return Observable.throw(error);


          if (error.status === 401) {
            if (this.refreshTokenInProgress) {
              // return this.ethersService.login().then(()=>{


              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // – which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                filter(result => {
                  // console.log(result, 'FILTERR');
                  return result !== null;
                }),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))).subscribe();
              // });
            } else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              // Call auth.refreshAccessToken(this is an Observable that will be returned)
              return this.ethersService.requestNewToken().then(() => {
                const token = localStorage.getItem('accessToken');
                // console.log(token, 'token!!!!!!!!!!!!!!!!');
                if (token && token !== 'undefined') {
                  // console.log(token, 'token');
                  // console.log(localStorage.getItem('accessToken'), 'localStorage.getItem(accessToken)');

                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(token);
                  this.ethersService.$wallet.next(this.ethersService.wallet);
                  // console.log(request, 'MUST AUTHENTICATE!');
                }

                return next.handle(this.addAuthenticationToken(request));
              }).catch((err: any) => {
                // console.log(err, 'errrrrr');
                this.refreshTokenInProgress = false;

                this.ethersService.logout();
                return Observable.throw(error);
              });
            }


            // if (this.refreshTokenInProgress) {
            //   // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            //   // – which means the new token is ready and we can retry the request again
            //   return this.refreshTokenSubject.pipe(
            //     filter(result => {
            //       console.log(result, 'FILTERR');
            //       return result !== null;
            //     }),
            //     take(1),
            //     switchMap(() => next.handle(this.addAuthenticationToken(request)))).subscribe();
            // } else {
            //   this.refreshTokenInProgress = true;
            //
            //   // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            //   this.refreshTokenSubject.next(null);
            //
            //   // Call auth.refreshAccessToken(this is an Observable that will be returned)
            //   return this.ethersService.requestNewToken().then((token) => {
            //     console.log(token, 'token');
            //     console.log(localStorage.getItem('accessToken'), 'localStorage.getItem(accessToken)');
            //     this.refreshTokenInProgress = false;
            //     this.refreshTokenSubject.next(token);
            //     console.log(request, 'MUST AUTHENTICATE!');
            //     return next.handle(this.addAuthenticationToken(request));
            //   }).catch((err: any) => {
            //     this.refreshTokenInProgress = false;
            //
            //     this.ethersService.logout();
            //     return Observable.throw(error);
            //   });
            // }
            // console.error(error.status);
            // console.error(error.message);
            // console.log('--- end of response---');
          }
        }
      )
    );
  }

  addAuthenticationToken(request) {
    // console.log(request.url, 'AUTHENTICATE');
    const accessToken = localStorage.getItem('accessToken');
    if (request.url.includes('api-nft.zertifier.com') && accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    } else {
      return request;
    }
  }
}
