<div class="modal-body">
  <img class="mb-3 d-block mx-auto custom-icon" src="assets/img/user-icon.png" height="100px" alt="">
  <div class="form-group">
    <label for="login-password" class="sr-only">{{ 'partials.login.enterPass' | translate }}</label>
    <input [(ngModel)]="password" (keyup.enter)="login()" [ngClass]="{'is-invalid': passwordErr}" id="login-password" class="form-control" type="password" [placeholder]="'partials.login.enterPass' | translate">
    <div *ngIf="passwordErr" class="invalid">
      {{ 'partials.login.invalidPassword' | translate }}
    </div>
  </div>
  <div id="log-in-alert" class="my-2"></div>
  <button (click)="login()" class="btn btn-lg btn-primary btn-block mb-2" type="button">{{ 'partials.login.login' | translate }}</button>
  <button class="btn btn-lg btn-secondary btn-block" (click)="clearWallet()" type="button">{{ 'partials.login.useOtherWallet' | translate }}</button>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">{{ 'generic.close' | translate }}</button>
</div>
