import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SignMessageBoxComponent} from './sign-message-box.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    SignMessageBoxComponent
  ],
    imports: [
        CommonModule,
        TranslateModule
    ],
  exports: [
    SignMessageBoxComponent
  ]
})
export class SignMessageBoxModule { }
