export const locale = {
  lang: 'en',
  data: {
    menu: {
      home: 'Home',
      send: 'Send file',
      receive: 'Receive file',
      ownerChecker: 'Ownership checker',
      contacts: 'Contacts',
      prices: 'Prices',
      documentation: 'Documentation',
      lang: {
        english: 'English',
        spanish: 'Español',
      },
      account: {
        sign: 'Sign',
        connect: 'Connect',
        wallet: 'Wallet',
        changeNetwork: 'Change network',
        addChain: 'Add Chain',
        identity: 'Identity',
        logout: 'Logout',
      },
    },
    home: {
      whatIsHash4Life: `
             <h5 class="blue-heading">What is Hash4Life</h5>
            <h1>
              We’ve built a platform that takes all the advantages from BlockChain technology
            </h1>
            <p class="lead">
              Most powerful & secure file transfer and ownership checker
            </p>
             `,
      menu: {
        sendFiles: 'Send file',
        receiveFiles: 'Receive file',
        ownershipCheck: 'Ownership check',
      },
      fileTransferInfo: `
             <h6 class="line-heading">File transfer</h6>
            <h1>
              The most secure file transfer
            </h1>
            <p class="lead">
              Send asymmetric encrypted files to an identity
            </p>
            <p class="text-justify">
              Our platform allows you to send any type of  asymmetric encrypted file to
              another identity.
            </p>
            <p class="text-justify">
              It also allows you to send encrypted invoices that can be signed and paid with <b
              class="font-weight-bold">Cryptocurrencies</b> or with <b class="font-weight-bold">Stable Tokens</b> like
              DAI or USDT.
            </p>
            <p class="text-justify">
              All sent files have a reading certificate and it can be signed for the receptor.
            </p>
             `,

      ownerShipInfo: `
            <h6 class="line-heading">ownership</h6>
            <h1>
             Verify your ownership
            </h1>
            <p class="lead">
              Check who is the owner of a specific file
            </p>
            <p class="text-justify">
              We Hash your file and save it in a decentralized Smart Contract.
            </p>
            <p class="text-justify">
              This hash is a unique file identifier and it can't be duplicated.
            </p>
            <p class="text-justify">
              The Smart Contract is totally independent and it proves who the owner is and when it was uploaded.
            </p>
      `,
      coinsAndTokens: {
        title: `
        <h5 class="blue-heading">Coins and Tokens</h5>
        <h1>
          Supported Coins and Tokens
        </h1>
        `,
        info: `
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/coins/eth.png">
          ETH
        </div>
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/coins/xdai.png">
          XDAI
        </div>
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/tokens/usdt.png">
          USDT
        </div>
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/tokens/dai.png">
          DAI
        </div>
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/tokens/wbtc.png">
          WBTC
        </div>
        <div class="col-4 col-sm-2 mb-3">
          <img class="supported-logo" src="assets/img/tokens/geur.png">
          GEUR (TEST)
        </div>
        `,
      },
      blockChains: {
        title: `
        <h5 class="blue-heading">BlockChains</h5>
        <h1>
          Supported BlockChains
        </h1>
        `,
        info: `
        <div class="col-4 col-sm-2 mb-3">
          ETH MainNet
        </div>
        <div class="col-4 col-sm-2 mb-3">
          ETH Ropsten
        </div>
        <div class="col-4 col-sm-2 mb-3">
          ETH Rinkeby
        </div>
        <div class="col-4 col-sm-2 mb-3">
          ETH Gorley
        </div>
        <div class="col-4 col-sm-2 mb-3">
          XDAI MainNet
        </div>
        <div class="col-4 col-sm-2 mb-3">
          More Chains coming soon...
        </div>
        `
      },
    },
    send: {
      header: `
        <h1 class="text-center">SECURE FILE TRANSFER</h1>
        <p class="slogan text-center d-none d-sm-block">Most secure and decentralized file transfer system</p>
      `,
      dropFilesMessage: `
        <h1>Drop files here</h1>
        <p>Add files by dropping them in this window</p>
      `,
      uploadForm: {
        basic: 'Basic',
        advanced: 'Advanced',
        yourWallet: 'Your Wallet',
        chain: 'Chain',
        smartContract: 'Smart Contract',
        categories: {
          title: 'Category',
          audio: 'Audio',
          image: 'Image',
          video: 'Video',
          academic: 'Academic',
          personal: 'Personal',
          working: 'Working',
          contract: 'Contract',
          fiscal: 'Fiscal',
          medical: 'Medical',
          invoice: 'Invoice',
          amount: 'Amount',
          currency: 'Currency',
          json: 'JSON',
          bankInfo: 'Bank info',
        },
        amount: 'Amount',
        currency: 'Currency',
        expiration: {
          days7: 'Expires after 7 Days',
          days30: 'Expires after 30 Days',
          days365: 'Expires after 1 Year',
        },
        hashToBlockChain: 'Hash to blockchain',
        encryptionSystem: 'Encryption system',
        symmetricEncryption: 'Symmetric encryption',
        asymmetricEncryption: 'Asymmetric encryption',
        receivePublicAddressPlaceholder: 'Receiver Public Address',
        findReceiverPublicKey: 'How to find the receiver public address',
        sendEncryptedFile: 'Send encrypted file',
        waitingForSignature: 'Waiting for your signature',
        encryptingFile: 'Encrypting your file',
        waitingForConfirmation: 'Waiting for confirmation',
        mining: 'Mining',
        transaction: 'Transaction',
      },
      filesSentForm: {
        title: 'File Sent Form',
        fileHash: 'File hash',
        verifyOwner: 'Verify owner',
        customFileName: 'Custom File name',
        fileName: 'File name',
        receiverName: 'Receiver name',
        receiverPublicKey: 'Receiver Public Key',
        category: 'Category',
        fileSize: 'File size',
        ipfsHash: 'IPFS hash',
        creation: 'Creation',
        expireIn: 'Expire in',
      },
      menuOptions: {
        menu: 'Menu',
        fileInfo: 'File Info',
        certificate: 'Certificate',
        increaseExpirationDate: 'Increase expiration date',
        download: 'Download',
        viewTransaction: 'View Transaction',
        remove: 'Remove',
      },
      linkForm: {
        linkWithEncryptionKey: 'Link with encryption key',
        linkWithoutEncryptionKey: 'Link without encryption key',
        key: 'Key',
        sendEmail: 'Send to an email'
      },
      filesSentTable: {
        title: 'Files Sent',
        file: 'File',
        category: 'Category',
        receive: 'Receiver',
        size: 'Size',
        expireIn: 'Expire in',
        creation: 'Creation',
      }
    },
    receive: {
      alerts: {
        invoicePayment: {
          title: 'Are you sure?',
          text: 'Do you want to pay this invoice?',
        },
        balanceError: {
          title: 'Error',
          text: 'You don\'t have enough balance in order to execute this payment',
        }
      },
      header: `
      <h1 class="text-center">SECURE FILE RECEIVER</h1>
      <p class="slogan text-center d-none d-sm-block">Most secure and decentralized file transfer system</p>
      `,
      receiveForm: {
        basic: 'Basic',
        advanced: 'Advanced',
        getAsymmetricPublicKey: 'Get Asymmetric Public Key',
        yourWallet: 'Your Wallet',
        chain: 'Chain',
        smartContract: 'Smart Contract',
        filesOwner: 'File\'s owner',
        yourAsymmetricPublicKey: 'Your Asymmetric Public Key',
        ipfsHash: 'IPFS HASH',
        password: 'Password',
        decryptAndDownloadFile: 'Decrypt and download',
        decryptingFile: 'Decrypting file',
        validateIpfsHash: 'Validate IPFS Hash',
        validatingIpfsHash: 'Validating your IPFS Hash',
      },
      filesReceivedForm: {
        title: 'File Received Form',
        fileHash: 'File hash',
        verifyOwner: 'Verify owner',
        customFileName: 'Custom File name',
        fileName: 'File name',
        senderName: 'Sender name',
        sender: 'Sender',
        senderAsymmetricPublicKey: 'Sender Asymmetric Public Key',
        category: 'Category',
        fileSize: 'File size',
        ipfsHash: 'IPFS hash',
        creation: 'Creation',
        expireIn: 'Expire in',
      },
      menuOptions: {
        menu: 'Menu',
        fileInfo: 'File info',
        download: 'Download',
        pay: 'Pay',
        viewTransaction: 'View transaction',
        sign: 'Sign',
        sendBack: 'Send back',
        remove: 'Remove',
      },
      filesReceivedTable: {
        title: 'Files Received',
        file: 'File',
        category: 'Category',
        sender: 'Sender',
        size: 'Size',
        expireIn: 'Expire in',
        creation: 'Creation',
      }
    },
    ownerChecker: {
      header: `
      <h1 class="text-center">OWNERSHIP CHECKER</h1>
      <p class="slogan text-center d-none d-sm-block">Most powerful document ownership checker</p>
      `,
      notInBlockchainAlert: {
        text: 'This hash is not uploaded to the Blockchain'
      },
      hashAlert: {
        alreadyRegistered: 'Hash already registered',
        uploadedCorrectly: 'Hash uploaded correctly',
        youAreOwner: 'You are the owner',
        owner: 'Owner',
        creationDt: 'Creation date',
        hash: 'Hash',
        network: 'Network',
        transaction: 'Transaction',
        viewTransaction: 'View transaction',
      },
      fileAlert: 'File is required',
      wallet: 'Wallet',
      certificateYourOwnership: 'CERTIFICATE YOUR FILES OWNERSHIP',
      higherSecurity: 'HIGHER SECURITY',
      moreEconomic: 'MORE ECONOMIC',
      forTesting: 'FOR TESTING',
      ownerCheckerForm: {
        lang: 'en',
        chooseFile: 'Choose file',
        basic: 'Basic',
        advanced: 'Advanced',
        yourWallet: 'Your Wallet',
        chain: 'Chain',
        smartContract: 'Smart Contract',
        certifyDocument: 'Certify document',
        verifyCertificate: 'Verify certificate',
        waitingForConfirmation: 'Waiting for confirmation',
        mining: 'Mining',
        transaction: 'Transaction',
      }
    },
    contacts: {
      header: `
      <h1 class="text-center">Contacts</h1>
        `,
      newContact: 'New contact',
      contactsTable: {
        alias: 'Alias',
        address: 'Address',
      },
    },
    contracts: {
      header: `
      <h1 class="text-center">Smart Contracts</h1>
        `,
      newContract: 'New contract',
      contractsTable: {
        contractAddress: 'Contract address',
        chain: 'Chain',
        name: 'Name',
        symbol: 'Symbol',
      },
    },
    products: {
      header: `
      <h1 class="text-center">Products</h1>
        `,
      newProduct: 'New product',
      productsTable: {
        contractAddress: 'Contract address',
        name: 'Name',
        description: 'Description',
        image: 'image',
        externalUrl: 'External URL',
      },
    },
    prices: {
      header: `
      <h1 class="text-center">Plans & Features</h1>
        `,
      plans: {
        plan1: {
          title: 'BASIC',
          description: `
          <h5>Free</h5>
            <ul class="fa-ul ml-4">
              <li><span class="fa-li"><i class="fas fa-check"></i></span>1GB storage</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Max file size 5MB</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>7 Days of file retention</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Transfer encrypted files</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Send invoices</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage files</li>
            </ul>
          `
        },
        plan2: {
          title: 'STANDARD',
          description: `
          <h5>9,90€ / month</h5>
            <ul class="fa-ul ml-4">
              <li><span class="fa-li"><i class="fas fa-check"></i></span>50GB storage</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Max file size 20MB</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>30 Days of file retention</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Transfer encrypted files</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Send invoices</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage files</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Support</li>
            </ul>
          `,
          buttonText: 'Get Standard'
        },
        plan3: {
          title: 'PREMIUM',
          description: `
          <h5>Coming soon</h5>
            <ul class="fa-ul ml-4">
              <li><span class="fa-li"><i class="fas fa-check"></i></span>50GB storage</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Max file size 20MB</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>30 Days of file retention</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Transfer encrypted files</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Send invoices</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Manage files</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Support</li>
              <li><span class="fa-li"><i class="fas fa-check"></i></span>Bulk upload</li>
            </ul>
          `,
          buttonText: 'Contact us'
        },
      }

    },
    wallet: {
      header: '',
      walletAddress: 'Wallet address',
      accountBalance: 'Account balance',
      availableTokens: 'Available tokens',
      getFree: 'Get free'
    },
    identities: {
      identity: 'Identity',
      company: 'Company',
      name: 'Name',
      lastName: 'Last name',
      phone: 'Phone',
      mobile: 'Mobile',
      email: 'Email',
      identityCard: 'VAT ID',
      address: 'Address',
      city: 'City',
      province: 'Province',
      postalCode: 'Postal code',
      country: 'Country',
    },
    documentation: {
      header: `
      <h1 class="text-center">Documentation</h1>
      <hr class="d-none d-sm-block">
      `,
      gettingStarted: {
        title: 'GETTING STARTED',
        description: `
        <p>
                  To mint NFT you need Metamask.<br>
                  Click
                  <a
                     href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                     target="_blank"><u>here</u></a> to install the extension.
                </p>
                <p>
                  Once you have MetaMask and a wallet, you will be able to connect to ZertiNFT platform.
                </p>
                <p>
                  Our platform can use Ethereum, Polygon, Gnosis, Binance and Rinkeby (testnet).
                </p>
                <img class="mb-3 mx-auto d-block" src="assets/img/documentation/metamask.jpg">

        `
      },
      sendEncryptedFiles: {
        title: 'SEND ENCRYPTED FILES',
        description: `
        Hash4life allows you to encrypt and send any type of file.<br>
                You have 2 type of options:
                <ul>
                  <li>
                    <b>Send file with Symmetric encryption:</b><br>
                    Your file will be encrypted with an automatic secure password.<br>
                    This file can only be decrypted with the password or with the owner's wallet signature.
                  </li>
                  <li>
                    <b>Send file with Asymmetric encryption:</b><br>
                    This is the most secure way of encryption.<br>
                    Your file will be encrypted with a wallet Public Key and it can only be decrypted with the private
                    key.
                  </li>
                </ul>
        `
      },
      receiveEncryptedFiles: {
        title: 'RECEIVE ENCRYPTED FILES',
        description: `
        On the "Receiver file" page you will be able to get your Asymmetric Public Key and see all your received
                files.<br>
                Also, you will be able to pay encrypted invoices and sign documents with your wallet.
        `
      },
      ownershipChecker: {
        title: 'OWNERSHIP CHECKER',
        description: `
       On the "Ownership checker" page you can upload a file and save the hash to the BlockChain so you can
                verify your ownership and when was created.
              `
      },
      wallet: {
        title: 'WALLET',
        description: `
      On the "Wallet" page you can see your actual coin and tokens balance.
              `
      }
    },
    partials: {
      sendEmailModal: {
        email: 'Email',
        from: 'From (optional)',
        to: 'To',
        message: 'Message (optional)',
        send: 'Send',
      },
      certificateModal: {
        document: 'DOCUMENT',
        certificate: 'CERTIFICATE',
        fileHash: 'FILE HASH',
        owner: 'OWNER',
        transaction: 'TRANSACTION',
        contract: 'CONTRACT',
      },
      hashInfoModal: {
        title: 'Hash info',
      },
      metamaskNotInstalledAlert: {
        title: 'MetaMask is not installed',
        text: `You must install MetaMask extension.<br><a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank" class="btn btn-secondary">
                  Click here to Install MetaMask
              </a>`
      },
      networkNotSupportedAlert: {
        title: 'Network not supported',
        text: `Your blockchain network is not supported`
      },
      increaseExpirationAlert: {
        title: 'Increase expiration',
        text: 'Do you want to set the file to expire after 30 days?',
        success: {
          title: 'Perfect',
          text: 'Your file is going to expire after 30 days'
        }
      },
      downloadAlert: {
        title: 'Downloading',
        text: 'Decrypting your file, wait a moment...'
      },
      exportQR: {
        title: 'Export QR code',
      },
      importQR: {
        title: 'Import QR code',
        cameraError: 'We don\'t have access to the camera (please, make sure the camera is enabled)',
      },
      connect: {
        title: 'Connect',
        webWallet: 'Web wallet',
      },
      walletInfo: {
        walletAddress: 'Wallet address',
        privateKey: 'Private key',
        mnemonicPhrase: 'Mnemonic phrase',
        balance: 'Balance',
        print: 'Print'
      },
      downloadKeystore: 'Download keystore',
      login: {
        login: 'Login',
        enterPass: 'Enter password',
        useOtherWallet: 'Use another wallet',
        invalidPassword: 'Invalid password',
      },
      signUp: {
        title: 'Sign up',
        newAccount: {
          title: 'New account',
          newAccount: 'New account',
          password: 'Password',
          repeatPassword: 'Repeat password',
          rememberPassword: `<b>Remember the password!</b><br>
               This passwort will encrypt your wallet in a local file.<br>
               With this password you will be able to login decrypting your wallet.`,
          generate: 'Crete new Account',
        },
        importKeystore: {
          title: 'Import Keystore',
          keystorePassword: 'Keystore password',
          errors: {
            errorKeystorePassword: '<b>Error:</b> Wrong password. Please make sure the password is correct.',
            errorKeystoreUpload: '<b>Error:</b> You need to upload the keystore and write the password.',
          }
        },
        importPrivateKey: {
          title: 'Import Private Key',
          privateKey: 'Private key',
          password: 'Password',
          repeatPassword: 'Repeat password',
          rememberPassword: `<b>Remember the password!</b><br>
               This passwort will encrypt your wallet in a local file.<br>
               With this password you will be able to login decrypting your wallet.`,
          errors: {
            errorPrivateKey: '<b>Error:</b> Wrong private key.',
          }
        },
        importMnemonicPhrase: {
          title: 'Import Mnemonic Phrase',
          mnemonicPhrase: 'Mnemonic Phrase',
          password: 'Password',
          repeatPassword: 'Repeat password',
          rememberPassword: `<b>Remember the password!</b><br>
               This passwort will encrypt your wallet in a local file.<br>
               With this password you will be able to login decrypting your wallet.`,

          errors: {
            errorMnemonicPhrase: '<b>Error:</b> Wrong Mnemonic phrase.',
          }
        },

        errorMnemonicPhrase: 'Frase mnemotécnica incorrecta',
        errorPrivateKey: 'Llave privada incorrecta',


        // `<b>Remember your password!</b><br>
        // This password encrypts your address in a keystore file.<br>
        // You will have to use it to log in with your wallet.
        errorRepeatPassword: 'Repite la contraseña correctamente',
        errorEmptyPassword: 'Introduce una contraseña',
      },
      connectMessageBox: {
        title: 'You must connect your Wallet',
        connect: 'Connect'
      },
      signMessageBox: {
        title: 'You must sign to get an Asymmetric Public Key',
        sign: 'Sign'
      },
      deniedMessageBox: {
        title: 'Wallet not allowed',
        walletNotAllowed: 'This wallet is not allowed',
        connectWithOtherWallet: 'Change wallet'
      },
      freeTrialMessageBox: {
        title: 'Wallet not allowed',
        walletNotAllowed: 'This wallet is not authorized. You can try {{freeDays}} days for free.',
        startFreeTrial: 'Start free trial'
      },
      network: {
        title: 'Network'
      },
      features: {
        feature1: `
         <img src="assets/img/icon-a.png" alt="icon">
        <h5>Secure & Decentralized</h5>
        <p>
          Your files will be encrypted and safely stored in a decentralized IPFS server cluster
        </p>
        `,
        feature2: `
        <img src="assets/img/icon-b.png" alt="icon">
        <h5>7 days of file retention</h5>
        <p>
          We pin and store your files 7 days <b>FOR FREE</b>. Optionally, you can use your preferred pin service
        </p>
        `,
        feature3: `
        <img src="assets/img/icon-c.png" alt="icon">
        <h5>Low Cost</h5>
        <p>
          It's affordable for everyone. You will only have to pay the gas price for a transaction and a little fee
        </p>
        `,
      },
    },
    generic: {
      close: 'Close',
      save: 'Save',
      download: 'Download',
      print: 'Print',
      accept: 'Accept',
      cancel: 'Cancel',
      set: 'Set',
      yes: 'Yes',
      no: 'No',
      error: 'Error',
      copyToClipboard: 'Copy to clipboard'
    },
    datatable: {
      emptyTable: 'No data available in table',
      info: 'Showing _START_ to _END_ of _TOTAL_ entries',
      infoEmpty: 'No entries',
      infoFiltered: '(filtered from _MAX_ total entries)',
      loadingRecords: 'Loading...',
      processing: 'Processing...',
      search: '<i class="fa fa-search" aria-hidden="true"></i>',
      searchPlaceholder: 'Search',
      lengthMenu: 'Show _MENU_',
      zeroRecords: 'No matching records found',
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Next',
        previous: 'Previous'
      },
      aria: {
        sortAscending: ': activate to sort column ascending',
        sortDescending: ': activate to sort column descending'
      }
    },
    footer: {
      privacy: 'Privacy',
      terms: 'Terms',
      support: 'Support',
    }
  }
};
