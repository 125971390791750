import {Component, OnInit} from '@angular/core';
import {EthersService} from '../../../../core/ethers/services/ethers.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../login/login.component';
declare var $: any;

@Component({
    selector: 'app-import-mnemonic',
    templateUrl: './import-mnemonic.component.html',
    styleUrls: ['./import-mnemonic.component.scss']
})
export class ImportMnemonicComponent implements OnInit {
     mnemonic: string;
    mnemonicErr: boolean;

    password: string;
    repeatPassword: string;

    passwordErr: boolean;
    repeatPasswordErr: boolean;
    passwordErrText: string;

    constructor(
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private walletService: EthersService
    ) {
    }

    ngOnInit() {
      this.mnemonic = '';
        this.mnemonicErr = false;
    }

    login() {
        this.mnemonicErr = false;
        const wallet = this.walletService.importWalletFromMnemonic(this.mnemonic, this.password);
        if (wallet) {
           this.activeModal.close();
        this.modalService.open(LoginComponent);
        } else {
            this.mnemonicErr = true;
        }
    }
}
