import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EthersService} from '../../../core/ethers/services/ethers.service';
import {SignupComponent} from '../signup/signup.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    password: string;
    passwordErr: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private walletService: EthersService,
        public modalService: NgbModal,
    ) {
    }

    ngOnInit() {
    }

    clearWallet() {
        this.walletService.clearKey();
        this.activeModal.close();
        this.modalService.open(SignupComponent, {size: 'lg', scrollable: true});

    }

    login() {
        try {
            this.walletService.importWalletFromStorage(this.password);
            if (this.walletService.webWallet) {
                this.activeModal.close();
            }
        } catch (e) {
            this.passwordErr = true;
        }

    }
}
