import * as daiAbiObj from 'src/app/core/ethers/contract/dai-abi.json';
import * as usdtAbiObj from 'src/app/core/ethers/contract/usdt-abi.json';
import * as wbtcAbiObj from 'src/app/core/ethers/contract/wbtc-abi.json';
import * as geurAbiObj from 'src/app/core/ethers/contract/geur-abi.json';
import * as ctroAbiObj from 'src/app/core/ethers/contract/ctro-abi.json';
import {NetworkObjectInterface} from './interfaces/network.interface';

const networks: NetworkObjectInterface = {
  1: {
    name: 'mainnet',
    connectionType: 'STANDARD',
    connectionAccess: 'homestead',
    symbol: 'ETH',
    icon: 'assets/img/coins/eth.png',
    contractAddress: '0x46C88Bbf081169D835da0b1f2E71CC9A5a49eE8a',
    rpcUrl: 'https://mainnet.infura.io/v3/undefined',
    explorer: 'https://etherscan.io',
    addressExplorer: 'https://etherscan.io/address/{walletAddress}',
    contractExplorer: 'https://etherscan.io/address/{contractAddress}',
    tokensExplorer: 'https://etherscan.io/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://etherscan.io/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://etherscan.io/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://etherscan.io/tx/{tx}',
    saveHashToChain: false,
    decimals: 18,
    isMetamaskDefault: true,
    tokens: {
      dai: {
        icon: 'assets/img/tokens/dai.png',
        tokenAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        abi: (daiAbiObj as any).default,
        decimals: 18
      },
      usdt: {
        icon: 'assets/img/tokens/usdt.png',
        tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        abi: (usdtAbiObj as any).default,
        decimals: 6
      },
      wbtc: {
        icon: 'assets/img/tokens/wbtc.png',
        tokenAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
        abi: (wbtcAbiObj as any).default,
        decimals: 8
      },
      ctro: {
        icon: 'assets/img/tokens/ctro.png',
        tokenAddress: '0xb3ab2b676a1efdb16a227e151dc31f5bd3856744',
        abi: (ctroAbiObj as any).default,
        decimals: 10
      }
    }
  },
  /*3: {
    name: 'ropsten',
    connectionType: 'STANDARD',
    connectionAccess: 'ropsten',
    symbol: 'ETH',
    icon: 'assets/img/coins/eth.png',
    contractAddress: '0xf89Bc85f2845464292633265A9A75af4FB70926f',
    rpcUrl: 'https://mainnet.infura.io/v3/undefined',
    explorer: 'https://ropsten.etherscan.io',
    addressExplorer: 'https://ropsten.etherscan.io/address/{walletAddress}',
    contractExplorer: 'https://ropsten.etherscan.io/address/{contractAddress}',
    tokensExplorer: 'https://ropsten.etherscan.io/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://ropsten.etherscan.io/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://ropsten.etherscan.io/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://ropsten.etherscan.io/tx/{tx}',
    saveHashToChain: false,
    decimals: 18,
    isMetamaskDefault: true,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0x8c26e4C1674D3d485e16C4ee35CD6f6Fca576338',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      },
      ctro: {
        icon: 'assets/img/tokens/ctro.png',
        tokenAddress: '0x4d6759509c82703aed1b57fb936a9f158d1a8a9a',
        abi: (ctroAbiObj as any).default,
        decimals: 10
      }
    }
  },
  4: {
    name: 'rinkeby',
    connectionType: 'STANDARD',
    connectionAccess: 'rinkeby',
    symbol: 'ETH',
    icon: 'assets/img/coins/eth.png',
    contractAddress: '0xE3449aF647646B0249B85Bf839175957A0d453D9',
    explorer: 'https://rinkeby.etherscan.io',
    addressExplorer: 'https://rinkeby.etherscan.io/address/{walletAddress}',
    contractExplorer: 'https://rinkeby.etherscan.io/address/{contractAddress}',
    tokensExplorer: 'https://rinkeby.etherscan.io/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://rinkeby.etherscan.io/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://rinkeby.etherscan.io/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://rinkeby.etherscan.io/tx/{tx}',
    saveHashToChain: false,
    decimals: 18,
    isMetamaskDefault: true,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0xDC92c1950C0A20b69749fdef65D58eE68D0C4b0E',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  },*/
  5: {
    name: 'goerli',
    connectionType: 'STANDARD',
    connectionAccess: 'goerli',
    symbol: 'ETH',
    icon: 'assets/img/coins/eth.png',
    contractAddress: '0x8A56054a5Cb1b3E0D3002B3F4Bb352f86c174009',
    explorer: 'https://goerli.etherscan.io',
    addressExplorer: 'https://goerli.etherscan.io/address/{walletAddress}',
    contractExplorer: 'https://goerli.etherscan.io/address/{contractAddress}',
    tokensExplorer: 'https://goerli.etherscan.io/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://goerli.etherscan.io/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://goerli.etherscan.io/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://goerli.etherscan.io/tx/{tx}',
    saveHashToChain: false,
    decimals: 18,
    isMetamaskDefault: true,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0xDC92c1950C0A20b69749fdef65D58eE68D0C4b0E',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  },
  100: {
    name: 'xdai',
    connectionType: 'URL',
    connectionAccess: 'mainnet',
    symbol: 'XDAI',
    icon: 'assets/img/coins/xdai.png',
    contractAddress: '0x8A56054a5Cb1b3E0D3002B3F4Bb352f86c174009',
    explorer: 'https://blockscout.com/poa/xdai',
    rpcUrl: 'https://rpc.gnosischain.com',
    addressExplorer: 'https://blockscout.com/xdai/mainnet/address/{walletAddress}',
    contractExplorer: 'https://blockscout.com/xdai/mainnet/address/{contractAddress}',
    tokensExplorer: 'https://blockscout.com/xdai/mainnet/token/{tokenAddress}/inventory',
    tokenExplorer: 'https://blockscout.com/poa/xdai/address/{wallet}/tokens/{tokenAddress}/token-transfers',
    tokenIdExplorer: 'https://blockscout.com/xdai/mainnet/token/{tokenAddress}/instance/{tokenId}/token-transfers',
    txExplorer: 'https://blockscout.com/xdai/mainnet/tx/{tx}',
    saveHashToChain: true,
    decimals: 18,
    isMetamaskDefault: false,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0x57Feb6FD4732B6f55Fec0ED2458F2BCe34a1F6DA',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  },
  137: {
    name: 'polygon',
    connectionType: 'URL',
    connectionAccess: 'mainnet',
    symbol: 'MATIC',
    icon: 'assets/img/coins/matic.png',
    contractAddress: '0x8A56054a5Cb1b3E0D3002B3F4Bb352f86c174009',
    explorer: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com',
    addressExplorer: 'https://polygonscan.com/address/{walletAddress}',
    contractExplorer: 'https://polygonscan.com/address/{contractAddress}',
    tokensExplorer: 'https://polygonscan.com/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://polygonscan.com/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://polygonscan.com/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://polygonscan.com/tx/{tx}',
    saveHashToChain: true,
    decimals: 18,
    isMetamaskDefault: false,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0x57Feb6FD4732B6f55Fec0ED2458F2BCe34a1F6DA',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  },
  56: {
    name: 'bnb',
    connectionType: 'URL',
    connectionAccess: 'mainnet',
    symbol: 'BNB',
    icon: 'assets/img/coins/bnb.png',
    contractAddress: '0x57Feb6FD4732B6f55Fec0ED2458F2BCe34a1F6DA',
    rpcUrl: 'https://bsc-dataseed.binance.org',
    explorer: 'https://bscscan.com',
    addressExplorer: 'https://bscscan.com/address/{walletAddress}',
    contractExplorer: 'https://bscscan.com/address/{contractAddress}',
    tokensExplorer: 'https://bscscan.com/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://bscscan.com/address/{wallet}/tokens/{tokenAddress}/token-transfers',
    tokenIdExplorer: 'https://bscscan.com/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://bscscan.com/tx/{tx}',
    saveHashToChain: true,
    decimals: 18,
    isMetamaskDefault: false,
    tokens: {},
  },
  97: {
    name: 'BNB Testnet',
    connectionType: 'URL',
    connectionAccess: 'testnet',
    symbol: 'BNB',
    icon: 'assets/img/coins/bnb.png',
    contractAddress: '0x57Feb6FD4732B6f55Fec0ED2458F2BCe34a1F6DA',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    explorer: 'https://testnet.bscscan.com',
    addressExplorer: 'https://testnet.bscscan.com/address/{walletAddress}',
    contractExplorer: 'https://testnet.bscscan.com/address/{contractAddress}',
    tokensExplorer: 'https://testnet.bscscan.com/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://testnet.bscscan.com/address/{wallet}/tokens/{tokenAddress}/token-transfers',
    tokenIdExplorer: 'https://testnet.bscscan.com/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://testnet.bscscan.com/tx/{tx}',
    saveHashToChain: true,
    decimals: 18,
    isMetamaskDefault: false,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0xf06643c043Dc67c5a3F12Aa953f8E5D2a8318C6F',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  },
  11155111: {
    name: 'sepolia',
    connectionType: 'STANDARD',
    connectionAccess: 'sepolia',
    symbol: 'ETH',
    icon: 'assets/img/coins/eth.png',
    contractAddress: '0x8A56054a5Cb1b3E0D3002B3F4Bb352f86c174009',
    explorer: 'https://sepolia.etherscan.io',
    addressExplorer: 'https://sepolia.etherscan.io/address/{walletAddress}',
    contractExplorer: 'https://sepolia.etherscan.io/address/{contractAddress}',
    tokensExplorer: 'https://sepolia.etherscan.io/token/{tokenAddress}#inventory',
    tokenExplorer: 'https://sepolia.etherscan.io/token/{tokenAddress}?a={wallet}',
    tokenIdExplorer: 'https://sepolia.etherscan.io/token/{tokenAddress}?a={tokenId}',
    txExplorer: 'https://sepolia.etherscan.io/tx/{tx}',
    saveHashToChain: false,
    decimals: 18,
    isMetamaskDefault: true,
    tokens: {
      geur: {
        icon: 'assets/img/tokens/geur.png',
        tokenAddress: '0xDC92c1950C0A20b69749fdef65D58eE68D0C4b0E',
        abi: (geurAbiObj as any).default,
        decimals: 18,
        testToken: true
      }
    }
  }
};
export default networks;
