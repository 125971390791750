import {Component, OnInit, ViewChild} from '@angular/core';
import {NewWalletComponent} from './new-wallet/new-wallet.component';
import {ImportWalletComponent} from './import-wallet/import-wallet.component';
import {ImportPrivateKeyComponent} from './import-private-key/import-private-key.component';
import {ImportMnemonicComponent} from './import-mnemonic/import-mnemonic.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {


  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
  }


  @ViewChild(ImportWalletComponent)
  public importWallet: ImportWalletComponent;

  @ViewChild(ImportPrivateKeyComponent)
  public importPrivateKey: ImportPrivateKeyComponent;

  @ViewChild(ImportMnemonicComponent)
  public importMnemonic: ImportMnemonicComponent;

  ngOnInit() {

  }


  openNewWalletModal() {
    this.modalService.open(NewWalletComponent, {size: 'lg'});
    this.activeModal.close();
  }


  openImportWalletModal(){
    this.modalService.open(ImportWalletComponent,{size: 'lg'});
    this.activeModal.close();
  }


  openImportPrivateKeyModal(){
    this.modalService.open(ImportPrivateKeyComponent,{size: 'lg'});
    this.activeModal.close();
  }


  openImportMnemonicModal(){
    this.modalService.open(ImportMnemonicComponent,{size: 'lg'});
    this.activeModal.close();
  }


}
