import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {EthersService} from '../../../../core/ethers/services/ethers.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../login/login.component';


@Component({
  selector: 'app-new-wallet',
  templateUrl: './new-wallet.component.html',
  styleUrls: ['./new-wallet.component.scss']
})
export class NewWalletComponent implements OnInit {
  step: string;
  wallet: any;
  password: string;
  repeatPassword: string;
  passwordErr: boolean;
  repeatPasswordErr: boolean;
  passwordErrText: string;

  constructor(
    private ethersService: EthersService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.password = '';
    this.repeatPassword = '';
    this.step = 'newPassword';
  }

  createWallet() {
    if (this.verifyPassword()) {
      this.wallet = this.ethersService.newWallet(this.password);
      this.step = 'walletInfo';
    }
  }


  verifyPassword(): boolean {
    this.passwordErr = false;
    this.repeatPasswordErr = false;
    if (this.password !== this.repeatPassword) {
      this.translate.get('signUp.errorRepeatPassword').subscribe((errorRepeatPassword: any) => {
        this.passwordErrText = errorRepeatPassword;
        this.passwordErr = true;
        this.repeatPasswordErr = true;
      });
      return false;
    }
    if (!this.password) {
      this.translate.get('signUp.errorEmptyPassword').subscribe((errorEmptyPassword: any) => {
        this.passwordErrText = errorEmptyPassword;
        this.passwordErr = true;
      });
      return false;
    }
    this.passwordErrText = '';
    return true;
  }

  login() {
    this.modalService.open(LoginComponent);
    //this.authService.login();
  }

}
